import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import {
  screen,
  innerWidth,
  mainWhite,
  transHover,
  mainBlack,
  highlight,
} from "../components/common/variables";
import { format } from "date-fns";
import ReactPlayer from "react-player";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xlarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: 20px;
    max-width: 280px;
    line-height: 1.35;
    @media ${screen.small} {
      font-size: 2.8rem;
      margin-top: 45px;
      max-width: 1040px;
    }
  }

  .published-date {
    color: ${mainBlack};
    font-size: 1rem;
    margin-top: 10px;
    @media ${screen.small} {
      font-size: 1.1rem;
    }
  }

  .content {
    max-width: 800px;
    margin-top: 25px;
    @media ${screen.small} {
      margin-top: 45px;
    }
    @media ${screen.large} {
      max-width: 1360px;
    }

    &__full-text {
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      strong {
        font-weight: 700;
      }

      h1 {
        font-size: 2.2rem;
        margin: 10px 0;
      }
      h2 {
        font-size: 2rem;
        margin: 10px 0;
      }
      h3 {
        font-size: 1.8rem;
        margin: 10px 0;
      }
      h4 {
        font-size: 1.6rem;
        margin: 10px 0;
      }
      h5 {
        font-size: 1.4rem;
        margin: 10px 0;
      }
      h6 {
        font-size: 1.2rem;
        margin: 10px 0;
      }
    }

    &__blockquote {
      max-width: 920px;
      margin: 25px auto;
      padding-left: 25px;
      position: relative;
      @media ${screen.small} {
        margin: 50px auto;
        padding-left: 45px;
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 1.1rem;

        &:hover {
          text-decoration: underline;
        }
      }

      &::before {
        background: ${highlight};
        content: "";
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        display: block;
        @media ${screen.small} {
          width: 7px;
        }
      }
    }

    &__left-aligned-img {
      display: flex;
      flex-direction: column;
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
        flex-direction: row;
      }

      ul,
      ol {
        margin: 15px 0 30px 0;
        padding-left: 30px;
        @media ${screen.small} {
          padding-left: 45px;
        }

        li {
          line-height: 1.5;
          list-style: inherit;

          margin-bottom: 16px;
        }
      }

      .img {
        width: 100%;
        margin-bottom: 15px;
        @media ${screen.small} {
          width: 50%;
          padding-right: 50px;
          margin-bottom: 0;
        }

        .caption {
          font-size: 0.8rem;
          margin-top: 8px;
          display: block;
          @media ${screen.small} {
            font-size: 1rem;
          }
        }
      }

      .description {
        width: 100%;
        @media ${screen.small} {
          width: 50%;
        }

        p {
          font-size: 1rem;
          font-weight: 300;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }

        h1 {
          font-size: 2rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }

        h2 {
          font-size: 1.8rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }

        h3 {
          font-size: 1.6rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }

        h4 {
          font-size: 1.5rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }
      }
    }

    &__right-aligned-img {
      display: flex;
      flex-direction: column;
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
        flex-direction: row;
      }

      ul,
      ol {
        margin: 15px 0 30px 0;
        padding-left: 30px;
        @media ${screen.small} {
          padding-left: 45px;
        }

        li {
          line-height: 1.5;
          list-style: inherit;

          margin-bottom: 16px;
        }
      }

      .img {
        width: 100%;
        margin-bottom: 15px;
        @media ${screen.small} {
          width: 50%;
          padding-left: 50px;
          margin-bottom: 0;
        }

        .caption {
          font-size: 0.8rem;
          margin-top: 8px;
          display: block;
          @media ${screen.small} {
            font-size: 1rem;
          }
        }
      }

      .description {
        width: 100%;
        @media ${screen.small} {
          width: 50%;
        }

        p {
          font-size: 1rem;
          font-weight: 300;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }

        h1 {
          font-size: 2rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.5rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }
      }
    }

    &__hyperlink {
      margin: 25px 0 50px 0;

      @media ${screen.small} {
        margin: 50px 0;
      }

      a {
        background: ${mainBlack};
        display: table;
        color: ${mainWhite};
        font-size: 1.1rem;
        padding: 13px 20px;
        transition: ${transHover};
        &:hover {
          background: ${highlight};
        }
      }
    }

    &__full-video {
      position: relative;
      padding-top: 56.25%;
      margin: 25px 0;
      @media ${screen.small} {
        margin: 50px 0;
      }

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .social-share {
    display: flex;
    align-items: center;
    max-width: 340px;
    margin-top: 80px;
    @media ${screen.small} {
      margin-top: 120px;
      justify-content: space-between;
    }

    p {
      font-size: 1.1rem;
    }

    &__icon {
      cursor: pointer;
      transition: ${transHover};
      outline: none;

      svg {
        width: 32px;
        height: 32px;
        margin: 0 14px;
        @media ${screen.small} {
          width: 35px;
          height: 35px;
        }
      }

      &:hover {
        color: ${highlight};
      }
    }
  }
`;

const BlogPage = (props) => {
  const titleTag = props.data.blog.data.title_tag;
  const metaDescription = props.data.blog.data.meta_description;
  const blogTitle = props.data.blog.data.title.text;
  const blogImg = props.data.blog.data.featured_image.fluid;
  const slices = props.data.blog.data.body;
  // console.log(props.data.blog.first_publication_date);

  // const publishedDate = props.data.blog.data.edit_published_date
  //   ? format(props.data.blog.data.edit_published_date, "MMMM YYYY")
  //   : format(props.data.blog.first_publication_date, "MMMM YYYY");

  // array of JSX with the content from Prismic slices
  const renderContent = slices.map((content) => {
    if (content.slice_type === `full_text`) {
      return (
        <div
          className="content__full-text"
          dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
          key={content.id}
        />
      );
    }

    if (content.slice_type === `blockquote`) {
      return (
        <div
          className="content__blockquote"
          dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
          key={content.id}
        />
      );
    }

    if (content.slice_type === `left_image_w__text`) {
      return (
        <div className="content__left-aligned-img" key={content.id}>
          <div className="img">
            {content.primary.image.fluid && (
              <Img fluid={content.primary.image.fluid} />
            )}
            <span className="caption">
              {content.primary.caption && content.primary.caption}
            </span>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === `right_image_w__text`) {
      return (
        <div className="content__right-aligned-img" key={content.id}>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: content.primary.description.html,
            }}
          />
          <div className="img">
            {content.primary.image.fluid && (
              <Img fluid={content.primary.image.fluid} />
            )}

            <span className="caption">
              {content.primary.caption && content.primary.caption}
            </span>
          </div>
        </div>
      );
    }

    if (content.slice_type === `hyperlink`) {
      return (
        <div className="content__hyperlink" key={content.id}>
          <a
            href={content.primary.full_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.primary.label}{" "}
          </a>
        </div>
      );
    }

    if (content.slice_type === `full_width_video`) {
      return (
        <div className="content__full-video" key={content.id}>
          <ReactPlayer
            className="video"
            url={content.primary.url}
            preload="true"
          />
        </div>
      );
    }
  });

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div>{blogImg && <Img fluid={blogImg} alt="banner" />}</div>

        <h1 className="heading">{blogTitle}</h1>
        {/* <p className="published-date">Published: {publishedDate}</p> */}
        <div className="content">{renderContent}</div>
      </Wrapper>
    </Layout>
  );
};

export default BlogPage;
export const data = graphql`
  query($id: String!) {
    blog: prismicBlog(id: { eq: $id }) {
      id
      tags
      first_publication_date
      data {
        title {
          text
        }

        featured_image {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        featured_description
        body {
          __typename
          ... on PrismicBlogBodyFullText {
            id
            slice_type
            primary {
              description {
                html
              }
            }
          }
          ... on PrismicBlogBodyBlockquote {
            id
            slice_type
            primary {
              description {
                html
              }
            }
          }
          ... on PrismicBlogBodyLeftImageWText {
            id
            slice_type
            primary {
              caption
              description {
                html
              }
              image {
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicBlogBodyRightImageWText {
            id
            slice_type
            primary {
              caption
              description {
                html
              }
              image {
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicBlogBodyHyperlink {
            id
            primary {
              full_url
              label
            }
            slice_type
          }
          ... on PrismicBlogBodyFullWidthVideo {
            id
            slice_type
            primary {
              url
            }
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
